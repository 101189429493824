<template>
  <div class="view-box">
    <div class="comment">
      <dt>{{$t('content.signup.popup.PersonalInfoUse.txt02')}}</dt>
      <dt>{{$t('content.signup.popup.PersonalInfoUse.txt03')}}</dt>
    </div>
    <dl class="terms">
      <dt>{{$t('content.signup.popup.PersonalInfo.txt04')}}</dt>
      <dd>{{$t('content.signup.popup.PersonalInfoUse.txt05')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt06')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt07')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt08')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt09')}}<br />
      <!-- {{$t('content.signup.popup.PersonalInfoUse.txt10')}}<br /> -->
      {{$t('content.signup.popup.PersonalInfoUse.txt11')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt12')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt13')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt14')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt15')}}<br />
      <!-- {{$t('content.signup.popup.PersonalInfoUse.txt16')}}<br /> -->
      {{$t('content.signup.popup.PersonalInfoUse.txt17')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt18')}}<br />
      </dd>
      <dt> {{$t('content.signup.popup.PersonalInfoUse.txt19')}}</dt>
      <dd>{{$t('content.signup.popup.PersonalInfoUse.txt20')}}<br />
      <!-- {{$t('content.signup.popup.PersonalInfoUse.txt21')}}<br /> -->
      {{$t('content.signup.popup.PersonalInfoUse.txt22')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt23')}}<br />
      <!-- {{$t('content.signup.popup.PersonalInfoUse.txt24')}}<br /> -->
      {{$t('content.signup.popup.PersonalInfoUse.txt25')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt26')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt27')}}<br />
      </dd>
      <dt> {{$t('content.signup.popup.PersonalInfoUse.txt28')}}</dt>
      <dd>{{$t('content.signup.popup.PersonalInfoUse.txt29')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt30')}}<br />
      <!-- {{$t('content.signup.popup.PersonalInfoUse.txt31')}}<br /> -->
      {{$t('content.signup.popup.PersonalInfoUse.txt32')}}<br />
      <!-- {{$t('content.signup.popup.PersonalInfoUse.txt33')}}<br /> -->
      {{$t('content.signup.popup.PersonalInfoUse.txt34')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt35')}}<br />
      </dd>
      <dt> {{$t('content.signup.popup.PersonalInfoUse.txt36')}}</dt>
      <dd>{{$t('content.signup.popup.PersonalInfoUse.txt37')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt38')}}<br />
      <!-- {{$t('content.signup.popup.PersonalInfoUse.txt42')}}<br /> -->
      </dd>
      <dt>
        <table class="privacyTable">
          <colgroup>
            <col width="50%">
            <col width="25%">
            <col width="25%">
          </colgroup>
          <tr>
            <th>{{$t('content.signup.popup.Management.head01')}}</th>
            <th>{{$t('content.signup.popup.Management.head02')}}</th>
            <th>{{$t('content.signup.popup.Management.head03')}}</th>
          </tr>
          <tr>
            <td>{{$t('content.signup.popup.Management.txt04')}}</td>
            <td>{{$t('content.signup.popup.Management.txt05')}}</td>
            <td>{{$t('content.signup.popup.Management.txt06')}}</td>
          </tr>
        </table>
      </dt>
      <dt> {{$t('content.signup.popup.PersonalInfoUse.txt43')}}</dt>
      <dd>{{$t('content.signup.popup.PersonalInfoUse.txt44')}}<br />
      <!-- {{$t('content.signup.popup.PersonalInfoUse.txt45')}}<br /> -->
      {{$t('content.signup.popup.PersonalInfoUse.txt46')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt47')}}<br />
      <!-- {{$t('content.signup.popup.PersonalInfoUse.txt48')}}<br /> -->
      {{$t('content.signup.popup.PersonalInfoUse.txt49')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt50')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt51')}}<br />
      </dd>
      <dt> {{$t('content.signup.popup.PersonalInfoUse.txt52')}}</dt>
      <dd>{{$t('content.signup.popup.PersonalInfoUse.txt53')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt54')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt55')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt56')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt57')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt58')}}<br />
      <!-- {{$t('content.signup.popup.PersonalInfoUse.txt59')}}<br /> -->
      {{$t('content.signup.popup.PersonalInfoUse.txt60')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt61')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt62')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt63')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt64')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt65')}}<br />
      <!-- {{$t('content.signup.popup.PersonalInfoUse.txt66')}}<br /> -->
      {{$t('content.signup.popup.PersonalInfoUse.txt67')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt68')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt69')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt70')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt71')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt72')}}<br />
      <!-- {{$t('content.signup.popup.PersonalInfoUse.txt73')}}<br /> -->
      {{$t('content.signup.popup.PersonalInfoUse.txt74')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt75')}}<br />
      </dd>
      <dt> {{$t('content.signup.popup.PersonalInfoUse.txt76')}}</dt>
      <dd>{{$t('content.signup.popup.PersonalInfoUse.txt77')}}<br />
      <!-- {{$t('content.signup.popup.PersonalInfoUse.txt78')}}<br /> -->
      {{$t('content.signup.popup.PersonalInfoUse.txt79')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt80')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt81')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt82')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt83')}}<br />
      <!-- {{$t('content.signup.popup.PersonalInfoUse.txt84')}}<br /> -->
      {{$t('content.signup.popup.PersonalInfoUse.txt85')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt86')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt87')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt88')}}<br />
      </dd>
      <dt> {{$t('content.signup.popup.PersonalInfoUse.txt89')}}</dt>
      <dd>{{$t('content.signup.popup.PersonalInfoUse.txt90')}}<br />
      <!-- {{$t('content.signup.popup.PersonalInfoUse.txt91')}}<br /> -->
      {{$t('content.signup.popup.PersonalInfoUse.txt92')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt93')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt94')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt95')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt96')}}<br />
      <!-- {{$t('content.signup.popup.PersonalInfoUse.txt97')}}<br /> -->
      {{$t('content.signup.popup.PersonalInfoUse.txt100')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt101')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt102')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt103')}}<br />
      <!-- {{$t('content.signup.popup.PersonalInfoUse.txt104')}}<br /> -->
      {{$t('content.signup.popup.PersonalInfoUse.txt105')}}<br />
      {{$t('content.signup.popup.PersonalInfoUse.txt106')}}<br />
      <br />
      </dd>
    </dl>
  </div>
</template>

<script>
/**
 * 개인정보 처리방침 (내용) Component
 * @see Privacy.vue
 * @see SignUpStep3.vue
 */
export default {
  name: 'PrivacyText',
};
</script>
<style>
.privacyTable table {
  table-layout: initial !important;
}
</style>

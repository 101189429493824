<template>
  <main class="report_group" id="content">
    <div class="report_box">
      <!-- 개인정보 처리방침 -->
      <div class="terms-title">
        <h3>{{ $t('content.signup.popup.PersonalInfo.txt01') }}</h3>
      </div>

      <PrivacyText />
    </div>
  </main>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import PrivacyText from './component/PrivacyText.vue';

/**
 * 개인정보 처리방침
 */
export default {
  name: 'MobilePrivacy',
  mixins: [
    mixinHelperUtils,
  ],

  components: {
    PrivacyText,
  },
};
</script>
